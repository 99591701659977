import { useRef, React, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  Group,
  Filter,
  Selection,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import SideBar from "../sideBar/SideBar";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import styled from "styled-components";

const ButtonStyle = styled.button`
  margin-top: 7px;
  width: 250px;
  height: 55px;
  background-color: #c7131c;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-color: #c7131c;
  border-radius: 5px;
  cursor: pointer;
`;
const ErrorText = styled.p`
  color: red;
`;

export default function ReportRunner() {
  const schema = yup.object().shape({
    FromDate: yup
      .string("validation.FromDate")
      .required("From Date is required"),
    ToDate: yup
      .string("validation.invalid ToDate")
      .required("To Date is required"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, value },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const navigate = useNavigate();
  const gridInstance = useRef(null);
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case "Excel Export":
        gridInstance.current.excelExport();
        break;
      case "CSV Export":
        gridInstance.current.csvExport();
        break;
    }
  };
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  async function regeisterHandler(data) {
    const runnerSchema = {
      FromDate: "",
      ToDate: "",
    };
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://vsoft.com-eg.net:9091/api/Home/V1/GetRunnersAppTimes",
        {
          ...runnerSchema,
          ...data,
        },
        {
          headers: {
            CompanyID: "999999",
            AccessToken: `${accessToken}`,
          },
        }
      );

      reset();
      setIsLoading(false);
      setDisable(false);
      setResponseData(response.data.data);
      setShowDataGrid(true);
      return response.data.Data;
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        // Handle 401 Unauthorized response
        localStorage.clear();
        navigate("/");
      }
    }
  }
  const runnerAmount =
    responseData && responseData.length > 0
      ? responseData[0].RunningHours * responseData[0].DeliveryCommission
      : "";
  if (Array.isArray(responseData)) {
    responseData.forEach((item) => {
      item.RunnerAmount = runnerAmount;
    });
  }
  const toolbarOptions = ["ExcelExport", "CsvExport"];
  return (
    <div className="control-pane">
      <SideBar />
      <form onSubmit={handleSubmit(regeisterHandler)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "25px",
            marginLeft: "20px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="From Date"
                  onChange={(date) => {
                    setValue("FromDate", date.toISOString());
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <ErrorText>{errors.FromDate?.message}</ErrorText>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="To Date"
                  onChange={(date) => {
                    setValue("ToDate", date.toISOString());
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <ErrorText>{errors.ToDate?.message}</ErrorText>
          </Box>
          <ButtonStyle
            onClick={() => {
              regeisterHandler();
            }}
            type="submit"
          >
            {isLoading ? <CircularProgress /> : `Show`}
          </ButtonStyle>
        </Box>
      </form>
      {showDataGrid && (
        <Box
          sx={{
            height: 450,
            width: "98%",
            margin: "0 auto",
          }}
          className="col-lg-9 control-section"
        >
          <GridComponent
            dataSource={responseData}
            toolbar={toolbarOptions}
            ref={gridInstance}
            selectionSettings={{ type: "checkbox" }}
            filterSettings={{ type: "Excel" }}
            allowSorting
            allowExcelExport
            allowPdfExport
            enableStickyHeader
            allowFiltering
            allowGrouping
            toolbarClick={toolbarClick}
          >
            <ColumnsDirective>
              <ColumnDirective width={40} type="checkbox"></ColumnDirective>
              <ColumnDirective
                headerText="ID"
                field="RunnerID"
                width="80"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="TotalMinutes"
                headerText="Total Minutes"
                textAlign="Center"
                width="150"
              ></ColumnDirective>
              <ColumnDirective
                field="RunningHours"
                headerText="Working Hours"
                width="110"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="RunningMinutes"
                headerText="Working Minutes"
                width="130"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="RunnerName"
                headerText="Runner"
                width="90"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="DeliveryCommission"
                headerText="Commission"
                width="150"
                textAlign="Center"
              ></ColumnDirective>
              <ColumnDirective
                field="RunnerAmount"
                headerText="Runner Amount"
                width="180"
                textAlign="Center"
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                Group,
                Page,
                Filter,
                Selection,
                Sort,
              ]}
            />
          </GridComponent>
        </Box>
      )}
    </div>
  );
}
