import React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Snackbar, CircularProgress, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const TypographyText = styled.h2`
  display: grid;
  place-items: center;
`;

const FlexDivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
`;
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #008f4a;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border-color: #008f4a;
  border-radius: 5px;
`;
export default function SignOut(props) {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: {},
  } = useForm();
  const { openLogOut, setOpenLogOut, loading } = props;
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = (signOut) => {
    if (signOut === "clickaway") {
      return;
    }
    setSnack(false);
  };
  async function handleLogOut(data) {
    const accessToken = localStorage.getItem("userStorage")
      ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
      : null;
    setDisable(true);
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://vsoft.com-eg.net:9091/api/AdminUsers/V1/Logout",
        {},
        {
          headers: {
            CompanyID: "999999",
            AccessToken: `${accessToken}`,
          },
        }
      );
      if (response.status === 401) {
        // Handle 401 Unauthorized response
        localStorage.clear();
        navigate("/");
      } else {
        // Handle other responses
        queryClient.invalidateQueries("Main");
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }

      setOpenLogOut(false);
      setIsLoading(false);
      setDisable(false);
    } catch (error) {
      console.error(error);
      setSnack(true);
      setIsLoading(false);
      setDisable(false);
    }
  }

  return (
    <div>
      <Modal open={openLogOut}>
        <form onSubmit={handleSubmit(handleLogOut)}>
          <Box sx={style}>
            <FlexDivCenter>
              <TypographyText>
                Are You Sure You Want To Log Out ?
              </TypographyText>
            </FlexDivCenter>
            <FlexDivCenter>
              <ButtonStyle variant="contained" color="primary" type="submit">
                {loading ? <CircularProgress color="secondary" /> : "Log Out"}
              </ButtonStyle>
              <ButtonStyle
                onClick={() => {
                  setOpenLogOut(false);
                }}
                variant="contained"
                color="primary"
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                Cancel
              </ButtonStyle>
            </FlexDivCenter>
          </Box>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {"Un Expected error"}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
