import "./App.css";
import { Route, Routes } from "react-router-dom";
import SignIn from "./components/signIn/SignIn";
import Main from "./Main";
import ReportRunners from "./components/report/ReportRunners";
import Runner from "./components/report/Runner";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/all-runners" element={<ReportRunners />} />
        <Route path="/runner" element={<Runner />} />
      </Routes>
    </div>
  );
}

export default App;
