import React from "react";
import SideBar from "./components/sideBar/SideBar";
import { Box } from "@mui/material";
import { useState } from "react";
import SignIn from "./components/signIn/SignIn";
import BgColor from "./assets/images/bgcolor.png";

function Main() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const CheckLogin = ({ isLoggedIn }) => {
    if (isLoggedIn === true) {
      return (
        <div>
          <SideBar />
          <Box sx={{ display: "grid", placeItems: "center" }}>
            <img src={BgColor} alt="Message Express" />
          </Box>
        </div>
      );
    } else {
      return <SignIn isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />;
    }
  };

  return (
    <div>
      <CheckLogin isLoggedIn={isLoggedIn} />
    </div>
  );
}

export default Main;
